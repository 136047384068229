.sidemenu-icons {
  width: 16px;
  height: 16px;
}

.dashboard-icon {
  background-image: url(/assets/images/svg/side-menu/dashboard.svg);
}
.medical-icon {
  background-image: url(/assets/images/svg/side-menu/medical-supply.svg);
}
.helpdesk-icon {
  background-image: url(/assets/images/svg/side-menu/help-desk.svg);
}
.store-icon {
  background-image: url(/assets/images/svg/side-menu/iq-store.svg);
}
.service-package-icon {
  background-image: url(/assets/images/svg/side-menu/service-package.svg);
}
.service-icon {
  background-image: url(/assets/images/svg/side-menu/services.svg);
}
.wallet-icon {
  background-image: url(/assets/images/svg/side-menu/wallet.svg);
}
.premise-icon {
  background-image: url(/assets/images/svg/side-menu/campus.svg);
}
.building-icon {
  background-image: url(/assets/images/svg/side-menu/building.svg);
}
.company-icon {
  background-image: url(/assets/images/svg/side-menu/company.svg);
}
.user-icon {
  background-image: url(/assets/images/svg/side-menu/users.svg);
}
.configuration-icon {
  background-image: url(/assets/images/svg/side-menu/configuration.svg);
}
.reports-icon {
  background-image: url(/assets/images/svg/side-menu/reports.svg);
}
.block-email-icon {
  background-image: url(/assets/images/svg/side-menu/block-emails.svg);
}
.announcement-icon {
  background-image: url(/assets/images/svg/side-menu/announcement.svg);
}
.offer-icon {
  background-image: url(/assets/images/svg/side-menu/offer.svg);
}
.event-icon {
  background-image: url(/assets/images/svg/side-menu/event.svg);
}
.audit-icon {
  background-image: url(/assets/images/svg/side-menu/audit.svg);
}
.meetingroom-icon {
  background-image: url(/assets/images/svg/side-menu/meeting-room.svg);
}
.visitor-icon {
  background-image: url(/assets/images/svg/side-menu/visitors.svg);
}
.roles-icon {
  background-image: url(/assets/images/svg/side-menu/roles.svg);
}
.cafeteria-icon {
  background-image: url(/assets/images/svg/side-menu/cafeteria.svg);
}
.foodcard-icon {
  background-image: url(/assets/images/svg/side-menu/food-card.svg);
}
.invoice-icon {
  background-image: url(/assets/images/svg/side-menu/invoice.svg);
}
.electric-icon {
  background-image: url(/assets/images/svg/side-menu/electricity.svg);
}
.space-icon {
  background-image: url(/assets/images/svg/side-menu/space-management.svg);
}
.airport-icon {
  background-image: url(/assets/images/svg/side-menu/airport-cab.svg);
}
.connect-icon {
  background-image: url(/assets/images/svg/side-menu/connect.svg);
}
.parking-icon {
  background-image: url(/assets/images/svg/side-menu/parking.svg);
}
.hotdesk-menu-icon {
  background-image: url(/assets/images/svg/side-menu/hotdesk.svg);
}
.mikiosk-icon {
  background-image: url(/assets/images/svg/side-menu/mikiosk.svg);
}
.transport-icon {
  background-image: url(/assets/images/svg/side-menu/transportation.svg);
}
.wfs-icon {
  background-image: url(/assets/images/svg/side-menu/wfs.svg);
}
// active menu icons in white colour
.active{
  .dashboard-icon {
    background-image: url(/assets/images/svg/side-menu/dashboard-white.svg);
  }
  .medical-icon {
    background-image: url(/assets/images/svg/side-menu/medical-supply-white.svg);
  }
  .helpdesk-icon {
    background-image: url(/assets/images/svg/side-menu/helpdesk-white.svg);
  }
  .premise-icon {
    background-image: url(/assets/images/svg/side-menu/campus-white.svg);
  }
  .building-icon {
    background-image: url(/assets/images/svg/side-menu/building-white.svg);
  }
  .company-icon {
    background-image: url(/assets/images/svg/side-menu/company-white.svg);
  }
  .reports-icon {
    background-image: url(/assets/images/svg/side-menu/reports-white.svg);
  }
  .block-email-icon {
    background-image: url(/assets/images/svg/side-menu/block-emails-white.svg);
  }
  .announcement-icon {
    background-image: url(/assets/images/svg/side-menu/announcement-white.svg);
  }
  .offer-icon {
    background-image: url(/assets/images/svg/side-menu/offer-white.svg);
  }
  .event-icon {
    background-image: url(/assets/images/svg/side-menu/event-white.svg);
  }
  .foodcard-icon {
    background-image: url(/assets/images/svg/side-menu/food-card-white.svg);
  }
  .parking-icon {
    background-image: url(/assets/images/svg/side-menu/parking-white.svg);
  }
}
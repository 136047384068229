$primary-color-dark: #00796b;
$primary-color: #009688;
$primary-color-light: #b2dfdb;
$primary-color-text: #ffffff;
$black-color: #000000;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #bdbdbd;
$white-color: white;
$primary-light: #d1e6ff;
$gray-medium-color: #6f6f6f;
$accent-color: #ff7e4e;
$dark-gray-color: #575757;

$primary: #0a23f6;
$primary-text: #0a23f6;
$secondary: #848484;
$success: #27c24c;
$info: #23b7e5;
$warning: #ff902b;
$danger: #f05050;
$dark: #3a3f51;
$light: #ffffff;
$primary-light: #d1e6ff;

$header-clr: #0a23f6;
$primary-bg: #ffffff;
$secondary-bg: #f5f5f5;
$primary-light: #d1e6ff;
$bg-3: #f4f4f4;
$bg-4: #ededed;
$sidebar-logo-bg: #2d41c3;
$dark-purple-bg: #3514a5;
$blue-bg: #3957ff;
$purple-blue-bg: #3b46d0;
$lightest-purple-bg: #eff0fc;

$border-clr-1: #e8e9ec;
$border-clr-2: #c3c3c3;

$font-clr: #4d4f5c;
$font-clr-2: #a3a6b4;
$heading-clr: #333333;
$font-white: #ffffff;
$card-border-color: #e3e5f3;
$visitor-primary: #0ecfc9;

// :root {
$star-size: 24px;
$star-color: $secondary;
$star-background: #fc0;
// }
$enable-negative-margins: true !default;

/* For use in src/lib/core/theming/_palette.scss */
$blue-violet-palette: (
    50 : #e2e5fe,
    100 : #b6bdfc,
    200 : #8591fb,
    300 : #5465f9,
    400 : #2f44f7,
    500 : #0a23f6,
    600 : #091ff5,
    700 : #071af3,
    800 : #0515f2,
    900 : #030cef,
    A100 : #ffffff,
    A200 : #e3e3ff,
    A400 : #b0b1ff,
    A700 : #9698ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$darkgreen-palette: (
    50 : #e1f1e3,
    100 : #b4dcba,
    200 : #82c48c,
    300 : #4fac5d,
    400 : #2a9b3b,
    500 : #048918,
    600 : #038115,
    700 : #037611,
    800 : #026c0e,
    900 : #015908,
    A100 : #8bff8e,
    A200 : #58ff5c,
    A400 : #25ff2b,
    A700 : #0bff12,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$cherry-red-palette: (
    50 : #fae3e0,
    100 : #f3b8b3,
    200 : #ec8980,
    300 : #e45a4d,
    400 : #de3626,
    500 : #d81300,
    600 : #d41100,
    700 : #ce0e00,
    800 : #c80b00,
    900 : #bf0600,
    A100 : #ffe7e7,
    A200 : #ffb5b4,
    A400 : #ff8281,
    A700 : #ff6968,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
/*===================Only roster module specific common styles will come here=================*/
$total-emp-legend:#211ce8;
$present-legend:#029a3d;
$ontime-legend:#1ce0e8;
$latein-legend:#a257b1;
$absent-legend:#fd0000;
$leaves-legend:#ffbb00;
$weekoff-legend:#4d84fe;
$regularised-legend: #FF8240;

.filter-dialog-menu{
    margin-top: 0.5rem;
    .mat-menu-content:not(:empty){
        padding: 0;
    }
}
.status-type{
  .active {
    color: $success;
  }
  .upcoming,.leaves {
      color: $warning;
  }
  .past,
  .cancelled {
      color: $danger;
  }
  .week-off{
      color: $weekoff-legend;
  }
  .regularised{
    color: $regularised-legend;
  }
  .on-time {
    color: $ontime-legend;
  }
  
  .late-in {
    color: $latein-legend;
  }
}

.custom-blue-btn {
    background-color: #1489e1;
    color: $white-color;
}
.legends-list {
    padding: 0;
    display: inline-block;
    margin: 0;
    li {
      list-style: none;
      padding-left: 2rem;
      display: inline;
      .legend-color {
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-right: 0.3rem;
        &.total-emp {
          background-color: $total-emp-legend;
        }
        &.present {
          background-color: $present-legend;
        }
        &.on-time {
          background-color: $ontime-legend;
        }
        &.late-in {
          background-color: $latein-legend;
        }
        &.absent {
          background-color: $absent-legend;
        }
        &.leaves {
          background-color: $leaves-legend;
        }
        &.week-off {
          background-color: $weekoff-legend;
        }
      }
    }
}

.mn-w-auto {
    min-width: auto;
}

.max-w-90 {
    max-width: 90px;
}

.max-w-140 {
  max-width: 140px;
}

.btn-group {
  .btn {
    border: 1px solid gray;
    padding: 5px 15px;
    &.active {
      background-color: $primary;
      border: 1px solid $primary;
      color: $white-color;
      z-index: 0;
    }
  }
}

/* Custom Calendar Styles for Attendance Status */
.custom-status-calendar {
  width: 100%;
  
  /* Hide the redundant month header inside the calendar body */
  .mat-calendar-body-label {
    // visibility: hidden;
    padding: 40px !important;
  }
  
  /* Month header styling */
  .mat-calendar-header {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 16px;
    
    .mat-calendar-controls {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      
      .mat-calendar-spacer {
        display: none;
      }
      
      /* Center the month/year display */
      .mat-calendar-period-button {
        order: 2;
        margin: 0 auto;
        font-size: 20px;
        font-weight: 500;
      }
      
      /* Position previous arrow to the left */
      .mat-calendar-previous-button {
        order: 1;
        margin: 0;
      }
      
      /* Position next arrow to the right */
      .mat-calendar-next-button {
        order: 3;
        margin: 0;
      }
    }
  }
  
  /* Table styling */
  // .mat-calendar-table {
  //   border-collapse: collapse;
  //   width: 100%;
  //   table-layout: fixed;
  // }
  
  /* Weekday header styling */
  .mat-calendar-table-header {
      th {
         /* Hide the visible abbreviated day name */
        span[aria-hidden="true"] {
          display: none;
        }
      
        /* Show the visually hidden full day name */
        span.cdk-visually-hidden {
          position: relative;
          margin: auto;
          height: auto;
          color: #333;
          font-size: 14px;
          font-weight: normal;
        }
      }
  }
  // .mat-calendar-table-header {
  //   th {
  //     padding: 10px 0;
  //     text-align: center;
  //     border: 1px solid #e0e0e0;
  //     background-color: #f5f5f5;
  //     font-weight: 500;
  //     &.mat-calendar-table-header-divider{
  //       display: none;
  //     }
  //   }
  // }
  
  /* Calendar body styling */
  // .mat-calendar-body {
  //   min-width: 100%;
  // }
  
  /* Calendar cells styling */
  // .mat-calendar-body-cell {
  //   position: relative;
  //   height: 100px;
  //   border: 1px solid #e0e0e0;
  //   padding: 0 !important;
    
  //   &::before {
  //     display: none;
  //   }
  // }
  
  /* Cell content styling */
  .mat-calendar-body-cell-content {
    height: 40px;
    width: 40px;
  }
  
  .mat-calendar-body-cell-container{
    border: 1px solid #ccc;
    padding: 40px !important;
  }

  /* Attendance status styles */
  @mixin attendance-mark{
    position: absolute;
    top: 50px;
    left: -2px;
    color: white;
    padding: 4px 0;
    text-align: center;
    width: 100px;
    font-size: 12px;
  }
  

  .present-day {
    .mat-calendar-body-cell-content::after {
      content: "Present";
      background-color: $present-legend;
      @include attendance-mark;
    }
  }
  
  .absent-day {
    .mat-calendar-body-cell-content::after {
      content: "Absent";
      background-color: $absent-legend;
      @include attendance-mark;
    }
  }
  
  .leaves-day {
    .mat-calendar-body-cell-content::after {
      content: "Leave";
      background-color: $leaves-legend;
      @include attendance-mark;
    }
  }
  
  .week-off-day {
    .mat-calendar-body-cell-content::after {
      content: "Week Off";
      background-color: $weekoff-legend;
      @include attendance-mark;
    }
  }
  
  .regularised-day {
    .mat-calendar-body-cell-content::after {
      content: "Regularized";
      background-color: $regularised-legend;
      @include attendance-mark;
    }
  }
  
  .on-time-day {
    .mat-calendar-body-cell-content::after {
      content: "On-Time";
      background-color: $ontime-legend;
      @include attendance-mark;
    }
  }
  
  .late-in-day {
    .mat-calendar-body-cell-content::after {
      content: "Late In";
      background-color: $latein-legend;
      @include attendance-mark;
    }
  }
  
  /* Today's date highlight */
  .today-date {
    border: 2px solid $primary !important;
  }
  
  /* Override default today styling */
  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border: 2px solid $primary;
  }
}

/*===================Only space module specific common styles will come here=================*/

.dropdown-zero-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0px;
    margin: 0;
  }
}

.w-30 {
  width: 30%;
}

.material-symbols-outlined.text-warning {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.text-warning {
  font-size: 18px;
}

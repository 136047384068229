/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
}

@import "./styles/variables";

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

body {
  &.mat-typography{
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #656565;
    background-color: #f5f7fa;
  }
}
//scss for global scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  background-color: $font-clr;
  background-clip: padding-box;
}

.sidebar::-webkit-scrollbar-track {
  background: inherit;
}

::-webkit-scrollbar-track {
  background: lightgray;
}
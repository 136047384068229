.top-heading {
  background-color: #fff;
  padding: 0.4rem;

  .mat-h1 {
    margin: 0;
    color: #4d4f5c;
    font-size: 1.3rem;

    @media only screen and (max-width: 576px) {
      line-height: normal;
      margin-top: 0;
    }
  }

  .xng-breadcrumb-root {
    display: flex;
    justify-content: flex-end;
  }
}
// material input css
.float-lbl-left {
  .mat-form-field {
    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          width: 0 !important;
        }
      }

      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          left: -30px;
        }
      }
    }
  }
}
.float-lbl-left {
  &.mat-form-field {
    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          width: 0 !important;
        }
      }

      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          left: -30px;
        }
      }
    }
  }
}

iq-input-count {
  .mat-form-field-appearance-outline {
    .mat-form-field-suffix {
      top: 0;
    }
  }
}

// mat stepper
.import-stepper {
  &.mat-stepper-horizontal {
    background-color: transparent;

    .mat-horizontal-stepper-header-container {
      background-color: #fff;
      margin: 0 1rem;
      padding-top: 1.313rem;
      border-radius: 0.375rem 0.375rem 0 0;
    }
  }

  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header.cdk-program-focused {
    background-color: transparent;
  }
}

iq-dashboard-number {
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}

.view-mode-data {
  .mat-caption {
    font-size: 11px;
    color: $gray-medium-color;
    margin: 0;
  }

  .mat-body-strong {
    font-size: 14px;
    color: $black-color;
    font-weight: normal;
  }

  .highlight-value {
    .mat-body-strong {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .vehicle-no {
    .mat-body-strong {
      color: $primary;
      font-weight: 600;
    }
  }
}

span.vehicle-img {
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin: 0.6rem 0;
  border-radius: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

span.escort-img {
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin: 0.6rem 0;
  border-radius: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.location-menu {
  &.mat-menu-panel {
    max-width: fit-content;
  }
}

.routes-tab {
  .mat-tab-link {
    height: auto;
    padding: 0 24px 12px;
    min-width: 200px;

    &.tab-completed {
      color: #0a23f6;
      opacity: 1;
    }

    flex-direction: column;

    .mat-icon {
      margin: 0 auto 3%;
      &.employee-icon {
        width: 28px;
        .material-symbols-outlined {
          font-size: 30px;
        }
      }
      &.escort-icon {
        width: 28px;
        height: 26px;
        .material-symbols-outlined {
          font-size: 28px;
        }
      }
    }
  }
  &.edit-routes-tab {
    .mat-tab-link {
      color: #000;
      opacity: 1;
    }
  }
  .tab-incomplete {
    &.mat-tab-label-active {
      color: #000;
      opacity: 1;
    }
  }
}

.origin-inp {
  .mat-form-field,
  &.mat-form-field {
    .material-icons {
      color: #009dff;
    }
  }
}

.destination-inp {
  .mat-form-field,
  &.mat-form-field {
    .material-icons {
      color: #048918;
    }
  }
}

.map-wrapper,
.map-wrapper-sm {
  google-map {
    height: 100%;

    .map-container {
      height: 100% !important;
    }
  }
}

iq-input-time {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      height: 36px;
    }

    .mat-form-field-infix {
      padding: 0em 0 0.5em 0;
    }

    .mat-input-element[type="time"] {
      margin-top: 4px;
      // height: 14px;
    }
  }
}

.input-time {
  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      height: 36px;
    }

    .mat-form-field-infix {
      padding: 0em 0 0.5em 0;
    }

    .mat-input-element[type="time"] {
      margin-top: 4px;
      // height: 14px;
    }
  }
}

.inner-tab-panels {
  .mat-tab-header {
    border: none;
  }

  .mat-tab-label-container {
    border: 1px solid #c1c9ff;
    background: transparent linear-gradient(180deg, #ffffff 0%, #eaecfa 100%) 0%
      0% no-repeat;

    .mat-tab-label {
      width: 50%;

      &.mat-tab-label-active {
        color: #0a23f6;
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #b9c1e5 100%) 0% 0% no-repeat;
        box-shadow: 0px 0px 6px #00000029;
        opacity: 1;
      }
    }
  }
}

.scroll-wrapper {
  max-height: 440px;
  overflow-y: auto;
}

.max-h-300 {
  max-height: 300px;
}
.mat-menu-panel {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  min-height: auto !important;
}
button {
  &.mat-menu-item {
    height: 28px;
    line-height: 28px;
  }
  &.mat-success {
    color: #fff;
    background-color: #16bf30;
  }
}

.preselected-item-row {
  background-color: rgba(63, 81, 181, 0.2);
}

.status {
  div {
    display: flex;
    align-items: center;

    mat-icon {
      font-size: 1.2rem;
      height: auto;
    }

    &.active {
      color: #16bf30;
    }

    &.inactive {
      color: #ff4937;
    }
    &.pending {
      color: #ffa000;
    }
    &.auto-inactive {
      color: #a725fc;
    }
    &.auto-rejected {
      color: #ff7e4e;
    }
    &.archived {
      color: #b0c4de;
    }
  }
}

.action-cell {
  max-width: 80px;
}

.selcted-lbl {
  color: #ff7e4e;
}
/*----------scss for open booking select from inventory management page starts----------*/
@mixin active-color {
  color: #16bf30;
}

.mat-active {
  &.mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        @include active-color;
      }
    }
    // .mat-form-field-label{
    //   @include active-color;
    // }
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        @include active-color;
      }
      &.mat-focused {
        .mat-form-field-outline-thick {
          @include active-color;
        }
      }
    }
    .mat-select-value-text {
      @include active-color;
    }
  }
  .mat-option {
    &.mat-selected {
      &:not(.mat-option-disabled) {
        @include active-color;
      }
    }
  }
}

@mixin inactive-color {
  color: #ff4937;
}

.mat-inactive {
  &.mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        @include inactive-color;
      }
    }
    // .mat-form-field-label{
    //   @include inactive-color;
    // }
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        @include inactive-color;
      }
      &.mat-focused {
        .mat-form-field-outline-thick {
          @include inactive-color;
        }
      }
    }
    .mat-select-value-text {
      @include inactive-color;
    }
  }
  .mat-option {
    &.mat-selected {
      &:not(.mat-option-disabled) {
        @include inactive-color;
      }
    }
  }
}
/*----------scss for open booking select from inventory management page ends----------*/

/*----------scss for slider from configuration page starts----------*/
.custom-slider {
  &.mat-slider-horizontal {
    .mat-slider-wrapper,
    .mat-slider-track-wrapper,
    .mat-slider-track-background,
    .mat-slider-track-fill,
    .mat-slider-ticks-container,
    .mat-slider-ticks {
      height: 6px;
    }
    .mat-slider-thumb {
      bottom: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      box-shadow: 0px 0 15px #ccc;
      border: 4px solid #fff;
    }
  }
  &.mat-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb {
    border-width: 4px;
  }
  &.mat-slider.cdk-focused .mat-slider-track-background,
  .mat-slider-track-background,
  &:hover .mat-slider-track-background,
  &.mat-slider.cdk-focused .mat-slider-track-background {
    background-color: #0a23f630;
  }
  &.mat-slider.mat-slider-disabled .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.mat-slider.mat-slider-disabled .mat-slider-track-fill {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
/*----------scss for slider from configuration page ends----------*/

/*----------scss for custom mat suffix starts----------*/
.custom-suffix-wrapper {
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: -3px;
    right: -7px;
  }
  .mat-form-field-suffix span {
    background-color: #eff0fc;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    padding: 0 12px;
  }
  &.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: -3px;
    right: -7px;
    span {
      background-color: #eff0fc;
      display: inline-flex;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      padding: 0 12px;
    }
  }
}
/*----------scss for custom mat suffix ends----------*/
.route-id {
  font-size: 1.25rem;
  color: #0a23f6;
  font-weight: bold;
}

.download-btn.mat-button-disabled {
  border-color: rgba(0, 0, 0, 0.26) !important;
}

.download-btn {
  .mat-button-wrapper {
    display: flex !important;
    align-items: center !important;
  }
}

/*------------- schedule & roster table css starts -------------*/
.action-wrap {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000;
}
.roster-menu {
  min-width: 210px !important;
  .mat-menu-item {
    span {
      min-width: 140px;
      display: inline-block;
      margin-left: 0.3rem;
      vertical-align: middle;
    }
  }
  .material-icons {
    font-size: 1rem;
  }
  .mat-icon {
    width: 1rem;
    height: 1rem;
  }
}
.schedule-tbl {
  width: 100%;
  text-transform: capitalize;
  th,
  td {
    border: 1px solid #ddd;
    padding: 0.8rem;
  }
  th {
    background-color: #eff0fc;
    border-top: none;
    text-align: center;
    font-weight: normal;
    color: #565656;
    b {
      color: #454545;
      font-size: 1.125rem;
    }
    &:first-child {
      border-left: 1px solid transparent;
      border-top-left-radius: 0.5rem;
      color: $black-color;
      text-align: left;
      @media only screen and (max-width: 576px) {
        min-width: 200px;
      }
    }
    &:last-child {
      border-right: 1px solid transparent;
      border-top-right-radius: 0.5rem;
    }
    &.active {
      background-color: $primary;
      color: $white-color;
      b {
        color: $white-color;
      }
    }
    .route-no-header {
      width: 5rem;
      display: inline-block;
    }
    .route-name-header {
      display: inline-block;
      width: calc(100% - 5rem);
    }
  }
  td {
    color: #606060;
    font-size: 0.875rem;
    &:not(:first-child) {
      min-width: 150px;
    }
    &:first-child {
      max-width: 18rem;
      @media only screen and (max-width: 576px) {
        min-width: 200px;
      }
    }
    &.active {
      color: $black-color;
    }
    .weekly-off-inner {
      padding: 0.8rem;
    }
    .route-no-circle-wrap {
      width: 5rem;
      .route-no-circle {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $primary-bg;
        border: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        justify-content: center;

        .route-no {
          margin: 0 auto;
          font-size: 14px;
          color: $black-color;
          font-weight: 500;
        }
      }
    }
    .route-detail {
      width: calc(100% - 5rem);
      color: $black-color;
      .route-txt {
        max-width: calc(100% - 24px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.weekly-off-wrap {
  position: relative;
  height: 4rem;
  .off-txt {
    background-color: $white-color;
    color: #b2b2b2;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 19px;
    width: 75%;
  }
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  line {
    stroke-width: 1;
    stroke: #ddd;
  }
}
/*------------- schedule & roster table css ends -------------*/
iq-panel-roster {
  width: 500px;
  display: block;
  overflow-x: hidden;
  @media only screen and (max-width: 576px) {
    width: 300px;
  }
  .mat-header-row {
    background-color: #eff0fc;
  }
}

@import "variables";
/*===================Core layout structure UI styling starts=================*/
//scss for left sidebar
sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.sidebar-logo {
  width: 60px;
  display: flex;
  align-items: center;
  background-color: $sidebar-logo-bg;
  padding: 1rem;
  justify-content: space-between;
  &.expand-menu {
    width: 200px;
  }
  &.collapse-menu {
    padding: 1rem 0.3rem;
    .brand-logo {
      display: none !important;
    }
    .mat-icon-button {
      width: auto;
      margin: 0;
      .mat-icon {
        width: 20px;
      }
    }
  }
}
.sidebar {
  position: fixed;
  top: 68px;
  left: 0px;
  width: 65px;
  height: 100vh;
  overflow-y: hidden;
  background-color: $light;
  z-index: 1000;

  @media only screen and (max-width: 768px) {
    transform: translate3d(-100%, 0, 0);
    will-change:
      transform,
      -webkit-transform;
    transition: all 0.5s ease-out;
  }

  &.expand-menu {
    width: 200px;

    @media only screen and (max-width: 768px) {
      transform: translate3d(0, 0, 0);
      transition: all 0.5s ease-out;
    }

    .brand-logo {
      display: block;
    }

    menubar li {
      a {
        justify-content: flex-start;
        &.has-child-item {
          justify-content: space-between;
          span {
            display: flex;
            align-items: center;
          }
        }
        i.arrow-sign,
        .menu-text {
          display: block;
        }
        .sub-menu-hover-default {
          display: none;
        }
      }

      ul li a {
        padding-left: 15px;
      }
    }
  }

  &.collapse-menu {
    &:hover {
      width: 200px;

      .brand-logo {
        display: block;
      }

      menubar li {
        a {
          // padding: 5px 10px;
          justify-content: flex-start;
          &.has-child-item {
            justify-content: space-between;
            span {
              display: flex;
              align-items: center;
            }
          }
          i.arrow-sign,
          .menu-text {
            display: block !important;
          }
          .sub-menu-hover-default {
            display: none;
          }
        }

        ul li a {
          padding-left: 15px;
        }
      }
    }

    &:not(:hover) {
      menubar li {
        a {
          padding: 5px 0;
          justify-content: center;

          i.arrow-sign,
          .menu-text {
            display: none !important;
          }
          .sub-menu-hover-default {
            display: block;
          }
        }

        ul > li > a {
          padding: 5px 0px 5px 10px;
          justify-content: start;
        }
      }
    }
  }

  .menubar-parent {
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
}

.sidebar-logo {
  .miqube-logo {
    mask-image: url(/assets/images/miqube-logo.svg);
    background-color: white;
    mask-size: 26px;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 36px;
    height: 36px;
  }

  .brand-logo {
    mask-image: url(/assets/images/brand-name-v1.svg);
    background-color: white;
    mask-size: 70px;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 74px;
    height: 16px;
  }
  .mat-icon-button {
    margin-left: 20px;
    color: #fff;
  }
}
menubar {
  ul {
    list-style: none; // Only the first item in sidebar
  }

  ul.sidebar-nav,
  ul.sidebar-nav-mobile {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 0;

    li a {
      color: $dark;
      text-decoration: none;

      &:hover {
        background-color: $secondary-bg;
      }
    }
    > li {
      border-bottom: 1px solid #ededed;
    }
    li {
      display: block;
      cursor: pointer;

      &.has-child-menu {
        background-color: $lightest-purple-bg;
        > a {
          // background: transparent
          //   linear-gradient(
          //     102deg,
          //     $dark-purple-bg 0%,
          //     $purple-blue-bg 91%,
          //     $blue-bg 100%
          //   )
          //   0% 0% no-repeat;
          // color: $white-color;
        }
        ul {
          &.has-child-li {
            padding-left: 0;
            a:hover {
              background-color: $primary-bg;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 3px;
                height: calc(100% - 15px);
                background-color: $accent-color;
              }
              i.menu-icon {
                background-color: $light;
              }
            }
          }
        }
      }

      a {
        padding: 10px;
        display: flex;
        align-items: center;
        position: relative;

        &.active {
          // background-color: $primary-bg;
          background: transparent
            linear-gradient(
              102deg,
              $dark-purple-bg 0%,
              $purple-blue-bg 91%,
              $blue-bg 100%
            )
            0% 0% no-repeat;
          color: $white-color;
          // &::before {
          //   content: "";
          //   position: absolute;
          //   left: 0;
          //   width: 3px;
          //   height: calc(100% - 15px);
          //   background-color: $accent-color;
          // }

          i.menu-icon {
            background-color: $light;
          }
        }

        i {
          font-size: 0.9rem;
          display: block;
          margin: 5px;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
//scss for layout wrapper
.main-wrapper {
  padding-left: 60px;
  width: 100%;
  will-change: auto;
  transition: padding 0.15s;
  &.expand-menu {
    padding-left: 200px;
  }
}
//scss for top header
.top-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent
    linear-gradient(270deg, $dark-purple-bg 0%, $blue-bg 100%) 0% 0% no-repeat;
  position: sticky;
  top: 0;
  padding: 0.5rem;
  height: 68px;
  // z-index: 1029;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .top-header {
    padding-left: 60px;
  }
  .main-wrapper {
    padding-left: 0;
    &.expand-menu {
      .top-header {
        padding-left: 0;
      }
    }
  }
}
.header-title-wrap {
  padding: 1rem;
  color: $white-color;
  font-weight: normal;
  line-height: normal;
  @media only screen and (max-width: 576px) {
    padding: 0 0 0 0.5rem;
  }
  h1 {
    color: $white-color;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0.2rem;
  }
}

//scss for top navbar
.navbar {
  background: transparent
    linear-gradient(270deg, $dark-purple-bg 0%, $blue-bg 100%) 0% 0% no-repeat;
  color: white;
  z-index: 10;
  position: relative;
  padding: 0.5rem 1rem;
}
.navbar-brand {
  display: inline-block;
}
.brand-name {
  color: $font-white;
  margin-left: 20px;

  img {
    height: 15px;
  }
}

.brand-logo svg {
  height: 35px !important;
  margin-left: 10px;
}

.nav-item {
  margin: auto;
  margin-left: 10px;
}

.nav-item svg {
  height: 15px;
  margin-top: -2px;
}

.user-block-picture {
  position: relative;
  margin: 0 auto;

  .img-thumbnail {
    padding: 1px;
    max-width: 35px;
    background-color: #ffffff;
  }
}

.user-block-info {
  padding-top: 5px;
  text-align: center;
  white-space: nowrap;

  .user-block-name,
  .user-block-role {
    display: block;
  }

  .user-block-name {
    color: #7d848f;
    text-transform: uppercase;
  }

  .user-block-role {
    font-size: 12px;
    color: #aaa;
  }
}

.content-body {
  padding: 0.5rem;

  @media only screen and (max-width: 576px) {
    padding: 0.938rem 0;
  }

  .heading {
    color: $primary;
    margin-left: -10px;
  }
}

.wrapper {
  overflow-x: hidden;
  &.login {
    background: url(/assets/images/login-background-v1.svg);
    background-size: contain;
    height: 100%;
  }
}

.content-heading {
  padding: 0.5rem;
  background: #fff;

  .title {
    font-size: 28px;
    font-weight: 400;
    color: $heading-clr;
    white-space: nowrap;
  }

  .links {
    font-size: 13px;
    padding-right: 10px;
  }

  .first-link {
    color: $header-clr;
  }

  svg {
    height: 15px;
    margin: -4px 5px 0 0;

    .st0 {
      fill: $secondary;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    padding-left: 0px;
  }

  .layout-overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    left: 0;
    display: none;
    background: #435971;
    opacity: 0.5;
    cursor: pointer;
    z-index: 3;

    &.expand-menu {
      display: block;
    }
  }
}

@media print {
  sidebar,
  .top-header,
  footer,
  tap-header,
  cafe-order-food > *,
  cafe-manage-order > *,
  cafe-view-allocated-items > *,
  wms-bulk-recharge-report > *,
  ak-mikiosk-list > *,
  cafe-notifications > *,
  cafe-counter-sales > *,
  cafe-multi-tenant-pricing > *,
  .message-container,
  .cdk-overlay-container,
  .modal-backdrop {
    display: none;
  }

  cafe-order-food cafe-print-receipt,
  cafe-manage-order cafe-print-receipt,
  cafe-notifications cafe-print-receipt,
  cafe-counter-sales cafe-print-receipt,
  cafe-view-allocated-items cafe-print-qrcode,
  wms-bulk-recharge-report wms-bulk-recharge-report-print,
  ak-mikiosk-list ak-print-kiosk-qrcode,
  cafe-multi-tenant-pricing cafe-print-qrcode {
    display: block !important;
  }

  // .main-wrapper {
  //   padding-left: 0;
  // }
  .main-wrapper.expand-menu {
    padding-left: 0 !important;
  }

  html,
  body {
    height: 99%;
  }
}
/*===================Core layout structure UI styling ends=================*/

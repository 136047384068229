@import "variables";
// ************** CSS for Material controls starts **************** //
//   Table scss //
.mat-table {
  display: table;
  width: 100%;
  border: 1px solid #dee1ef;
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 10px;
    vertical-align: middle;
  }
}

.mat-header-row {
  background: #eaecfa linear-gradient(180deg, #fff 0%, #eaecfa 100%) 0% 0%
    no-repeat;
  border-bottom: 2px solid #dee1ef;
  min-height: auto;
  padding: 0.6rem 0;
  box-shadow: 0px 0px 10px #858caf4d;
}

.mat-header-cell {
  font-size: 13px;
  color: #212529;
  padding: 0 0.5rem;
  width: max-content;

  &.min-w-130 {
    min-width: 130px;
  }

  &.min-w-105 {
    min-width: 105px;
  }
}
.mat-row {
  border-bottom: none;
}
.mat-row:nth-of-type(2n) {
  background-color: #fafafa;
}

.mat-table .mat-row:hover {
  background-color: #d5d5d5;
}

.mat-cell {
  font-size: 13px;
  padding: 0 0.5rem;

  &.min-w-130 {
    min-width: 130px;
  }

  &.min-w-105 {
    min-width: 105px;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;

    &.inner-data {
      width: 70%;
    }
  }
}

.mat-paginator-container {
  justify-content: space-between !important;
}

@media only screen and (max-width: 576px) {
  .mat-paginator-container {
    .mat-paginator-range-actions {
      flex-wrap: wrap;
    }
    .mat-paginator-range-label {
      margin: 0 2rem 0 0;
    }
  }
}

// mat-divider css
.mat-divider {
  &.mat-divider-vertical {
    margin: 0.9rem;
  }

  &.custom-divider {
    margin: 0 auto;
    width: 1px;
  }
}

// mat-snackbar css
.mat-simple-snack-bar-content {
  width: 100%;
  text-align: center;
}

.mat-option {
  font-size: 13px;
  line-height: normal !important;
  height: 32px !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.4em 0 0.5em 0;
}

.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  // -webkit-transform: translateY(-1em) scale(.75);
  transform: translateY(-1em) scale(0.75);
}

.mat-checkbox-checkmark {
  height: 16px !important;
}

.cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop {
  z-index: 9999 !important;
}

// I added "cdk-overlay-backdrop" class to fix the issue of mat-select not getting closed when inside mat-dialog.
//If any consequences on other pages (I believe there shouldn't be), please discuss with me before removing it.

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999 !important;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #158ae1;
  border-color: #158ae1;
}

.mat-checkbox-mixedmark {
  width: calc(100% - 6px) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white;
  border-radius: 5px;
}
.mat-checkbox-layout .mat-checkbox-label {
  font-weight: normal;
}

/*------- common scss for input type time, date for icon styling starts --------*/
mat-datepicker-toggle {
  .mat-icon-button {
    color: $primary;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  padding: 0;
  margin: 0 5px 0 0;
  font-size: 18px;
  filter: brightness(0) saturate(100%) invert(11%) sepia(99%) saturate(6615%)
    hue-rotate(237deg) brightness(97%) contrast(98%);
}
/*------- common scss for input type time, date for icon styling ends--------*/
.mat-dialog-title {
  color: $font-white;
  padding: 5px 10px;
  background-color: $primary;
  margin: -24px -24px 0 -24px !important;
  .btn-close {
    color: $font-white;
  }
  h2,
  h3 {
    color: $primary-color-text;
  }
}
.mat-dialog-container {
  .mat-dialog-content {
    margin: 2rem -24px 0;
  }
}

.mat-menu-panel.allocation-menu {
  min-height: auto;
  min-width: auto;
}

// :host ::ng-deep {
.mat-chip-list {
  margin: 1rem 0;
  display: block;

  .mat-chip {
    background-color: #d5e4ff;
    color: #0a23f6;
    font-weight: normal;
    border-radius: 0.5rem;
    &.mat-standard-chip {
      .mat-chip-remove {
        color: #3957ff;
        opacity: 1;
      }
    }
    .mat-icon {
      color: #3957ff;
      opacity: 1;
    }
    &.invalid-email {
      background-color: #f0000010;
      border: 1px solid #f00;
      color: #f00;

      .mat-icon {
        color: #f00;
        opacity: 1;
      }
    }
  }
}

.mat-menu-panel {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  min-height: auto !important;
}
button {
  &.mat-menu-item {
    height: 28px;
    line-height: 28px;
  }
  &.mat-success {
    color: #fff;
    background-color: #16bf30;
  }
}

.mat-snack-bar-container.info-snackbar {
  background-color: #ffecd0;
  .snack-bar-container {
    display: flex;
    align-items: center;
    flex-flow: column;
    background-color: #ffecd0;
    color: #ff9700;
  }
}

/*==========================material control global scss from TRASPORT module starts=================*/

.mat-form-field {
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  .mat-input-element {
    &::placeholder {
      font-size: 0.9rem;
    }
  }

  .mat-select-placeholder {
    font-size: 0.9rem;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-prefix {
      top: 0;
    }
  }
}

mat-stepper {
  .mat-horizontal-content-container {
    @media only screen and (max-width: 576px) {
      padding: 0 0.5rem;
    }
  }
}

// mat checkbox css
.mat-checkbox {
  .mat-checkbox-layout {
    @media only screen and (max-width: 576px) {
      white-space: normal;
    }
  }
}

/*==========================material control global scss from TRASPORT module ends=================*/

@media only screen and (max-width: 992px) {
  .mat-datepicker-content-touch {
    max-height: 85vh !important;
  }
}

@media only screen and (max-height: 350px) {
  .mat-datepicker-content-touch {
    max-height: 100vh !important;
  }
}
.mat-menu-panel {
  max-width: 380px !important;
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: #212529;
}

.mat-menu-panel.header-notification {
  width: 400px;
  max-width: 400px;
  max-height: 300px;
  overflow-x: hidden;
}

@media (max-width: 576px) {
  .mat-menu-panel.header-notification {
    width: 300px;
    max-width: 300px;
    max-height: 200px;
    overflow-x: hidden;
  }
}

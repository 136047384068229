@import "variables";
@import "../theme/miqube-theme.scss";
@import "../styles.scss";
@import "side-menu-icon.scss";
@import "core-layout.scss";
@import "material-global.scss";
// regular style toast
// @import "../../node_modules/ngx-toastr/toastr.css";

/*===================common cotrols UI styling starts=================*/
.table {
  border: 1px solid #dee1ef;
  thead {
    border-radius: 4px;
    border-bottom: 2px solid #dee1ef;
    background: #eaecfa linear-gradient(180deg, #fff 0%, #eaecfa 100%) 0% 0%
      no-repeat;
    box-shadow: 0px 0px 10px #858caf4d;
    th {
      background-color: transparent;
    }
  }
}

.table th,
.table td {
  padding: 12px;
  border: none;
  border-bottom: none;
  font-size: 13px;
}

.table-hover tbody tr:hover {
  background-color: #f5f6fa;
}

.table.no-border td {
  border: none;
}

.table > tbody > tr:nth-of-type(even) > * {
  background-color: #fafafa !important;
}

.table > tbody > tr:nth-of-type(odd) > * {
  background-color: #fff !important;
}

.table-striped tbody tr:nth-of-type(odd):hover,
.table-striped tbody tr:nth-of-type(even):hover {
  background: #d5d5d5;
}

.table a {
  margin-right: 10px;
}

.popOverClass {
  border-color: #4aa2e6 !important;

  .arrow {
    &::before {
      border-top-color: #4aa2e6 !important;
      left: -10px;
    }
    &::after {
      left: -9px;
    }
  }

  .popover-body {
    padding: 1rem !important;
  }
}

.popOverTable td {
  border: none !important;
  font-size: 11px;
  padding: 3px 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: $primary;
  text-transform: capitalize;
}

a {
  cursor: pointer;
  color: $primary;
}

.card {
  width: auto;
  padding: auto;
  height: auto;
  float: left;

  .card-body {
    padding: 10px;
  }

  .card-heading {
    padding: 10px;
    color: $primary;
  }

  .card-logo {
    font-size: 12px;
    background: #9eeae8;
    padding: 5px 8px;
    margin-right: 8px;
    font-weight: bold;
  }

  .name {
    font-weight: bold;
  }

  .date {
    float: right;
    font-size: 12px;
  }
}

/* modal window styles */

.modal-lg {
  max-width: 1000px;
  // margin-right: 0;
}

.modal-header {
  background: $primary;
  border-radius: 0;
  font-size: 15px;

  .modal-title {
    font-weight: bold;
    color: white;
  }

  .close {
    font-size: 18px;
    color: white;
  }
}

.modal-attached {
  position: absolute;
  top: 10px;
  right: 100px;
  bottom: 0;
  left: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}

.modal-dialog .content-body {
  box-shadow: none;
  padding: 0 15px;
}
/* End of modal window styles */

.content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-inputs {
  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      @media only screen and (max-width: 576px) {
        padding-bottom: 1.34375em;
      }
    }
  }
}

// .input-time {
//   &.mat-form-field-appearance-outline {
//     .mat-form-field-outline {
//       height: 36px;
//     }

//     .mat-form-field-infix {
//       padding: 0em 0 0.5em 0;
//     }

//     .mat-input-element[type="time"] {
//       margin-top: 4px;
//     }
//   }
// }

// for input type time extra height correction
.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    .mat-input-element[type="time"] {
      margin-top: 4px;
      &::after {
        white-space: normal;
      }
    }
  }
}

.action-cell {
  max-width: 80px;
}

.multi-action-cell {
  max-width: 100px;
}

.no-data-lbl {
  color: #ff7e4e;
}

.card-bg {
  background-color: $white-color;
}
.xng-breadcrumb-trail {
  word-break: break-all;
}
/*===================common cotrols UI styling ends=================*/
.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 2px 10px;
}

.arrow.down {
  transform: rotate(45deg);
}

.arrow.up {
  transform: rotate(225deg);
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.btn-group {
  cursor: pointer;
}

.asterik {
  color: $danger;
}

.icon {
  mask-repeat: no-repeat;
  mask-position: center;
  display: block;
  mask-size: 24px;
  height: 32px;
  width: 32px;
}

.bg-danger {
  background-color: $danger;
}

.bg-success {
  background-color: $success;
}

.bg-primary {
  background-color: $primary;
}

.bg-warn {
  background-color: $warning;
}

.blockquote {
  font-size: 1.2rem;
}

.example-spacer {
  flex: 1 1 auto;
}

.success-snackbar {
  background: lightgreen;
  color: darkgreen;
  text-align: center;
}

.failure-snackbar {
  background: lightsalmon;
  color: crimson;
}

.download-btn.mat-button-disabled {
  border-color: rgba(0, 0, 0, 0.26) !important;
}

.download-btn {
  .mat-button-wrapper {
    display: flex !important;
    align-items: center !important;
  }
}

.form-control {
  border: 1px solid $border-clr-1;
  border-radius: 5px;
  &:focus {
    box-shadow: none;
  }
  &[disabled] {
    background: #e9ecef;
  }
  &[readonly] {
    background: inherit;
  }
}

label {
  margin: 0;
}

.controls {
  text-align: right;
  margin: 20px 0 30px 0;
}

.controls button {
  margin-left: 10px;
  color: white;
}

.btn-primary,
.btn-outline-primary {
  padding: 5px 15px;
}

.btn-standard-width {
  min-width: 110px !important;
}

.btn-transparent {
  border: 1px solid white;
  color: white;
  background: transparent;
  padding: 3px 20px;
  font-size: 13px;
}

.btn-transparent:hover,
.btn-transparent.active {
  background: white;
  color: $header-clr;
}

.btn-round,
.btn-round:hover {
  border-radius: 40px;
}

.funnel {
  height: 20px;
  color: $secondary;
}

svg {
  height: 20px;
}

pagination {
  float: right;
}

//panel styles
.panel {
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid $secondary;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.panel-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.location-section {
  background: $primary-bg;
  margin: 10px;
  padding: 10px;
}

.info-icon {
  background: url(/assets/images/info-icon-v1.svg) no-repeat;
  background-size: 12px 12px;
  width: 12px;
  height: 12px;
  float: left;
}

.lock-icon {
  background: url(/assets/images/lock-icon-v1.png) no-repeat;
  width: 12px;
  height: 12px;
  background-size: contain;
  display: inline-block;
}

.sprite-icon {
  background: url(/assets/images/icons-l2.svg) no-repeat;
  background-size: 400px;
  width: 16px;
  height: 16px;
}

.action-menu-icons {
  width: 13px;
  height: 13px;
  mask-position: center;
  background-color: #000;
  mask-repeat: no-repeat;
  mask-size: 13px;
  display: inline-block;
  margin-right: 5px;
}

.clone {
  mask-image: url(/assets/images/svg/Clone.svg);
}

.allocate {
  mask-image: url(/assets/images/svg/Allocate.svg);
}

.facility-icons {
  width: 25px;
  height: 35px;
  // background-repeat: no-repeat;
  mask-position: center;
  background-color: #000;
  mask-repeat: no-repeat;
  mask-size: 16px;
}

.ck-content {
  height: 250px;
}

.upload-section {
  .upload-link {
    font-weight: bold;
    font-size: 15px;
    color: $primary;
    margin: 5px;
    cursor: pointer;
  }
}

.attachments {
  margin: 5px;
  background: $bg-4;
  width: 50%;
  font-weight: bold;
  padding: 3px 10px;
}

.section {
  background: $bg-3;
  margin: 10px 0;

  h5 {
    padding: 10px;
  }
}

.wordwrap {
  word-wrap: break-word;
}

// For ng2-select component
.custom-ng-select {
  .ui-select-choices.dropdown-menu {
    display: block;
  }

  .btn {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $border-clr-1;
    padding-right: 5px;
    padding-top: 6px;
  }

  .dropdown-toggle {
    float: right;
  }

  .dropdown-toggle::after {
    vertical-align: 0;
    border-top: 0.5em solid;
    border-right: 0.2em solid transparent;
    border-left: 0.2em solid transparent;
  }

  .ui-select-match-text {
    color: #495057;
  }
}

.conversations {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px 10px 0;
}

.load-more {
  margin: 10px auto;
  padding: 5px 15px;
  border-radius: 20px;
  width: 200px;
  text-align: center;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 5px lightgrey;
  font-weight: bold;
  cursor: pointer;
}

.note-btn {
  margin-left: 10px;
  margin-top: 5px;
}

// success and error message styles
.message-container {
  background: rgb(200, 247, 204);
  padding: 20px;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  z-index: 100000;
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
}

.message-container.error {
  background: #ffe5e5;
  color: rgb(182, 57, 57);
}

.audit-section {
  padding: 10px;
  //max-height: 400px;
  overflow-y: auto;

  .date {
    color: $primary;
    font-weight: bold;
    font-size: 13px;
  }

  .text {
    color: $heading-clr;
    margin-top: 5px;
  }

  pagination {
    float: none;
  }
}

.ngui-auto-complete > ul {
  background-color: white;
}

.ngui-auto-complete > ul li {
  border: none !important;
  font-weight: bold !important;
}

.ngui-auto-complete > ul li:hover {
  color: white;
  background-color: #0e7fe1 !important;
}

.ngui-auto-complete > ul li.selected {
  color: white;
  background-color: #0e7fe1 !important;
}

.type-ahead-field i.fa.fa-search {
  display: none !important;
}

.type-ahead-field .list-group {
  width: 70%;
}

.type-ahead-field .list-group .ta-item.list-group-item:hover {
  background-color: #0e7fe1;
  color: white;
}

.type-ahead-field .list-group .ta-item.list-group-item {
  text-align: left;
}

.pointerCursor {
  cursor: pointer;
}

.ngui-auto-complete > ul {
  background-color: white;
}

.ngui-auto-complete > ul {
  background-color: white;
}

.ngui-auto-complete > ul li {
  border: none !important;
  font-weight: bold !important;
}

.ngui-auto-complete > ul li:hover {
  color: white;
  background-color: #0e7fe1 !important;
}

.ngui-auto-complete > ul li.selected {
  color: white;
  background-color: #0e7fe1 !important;
}

// Calendar Styles
.calendar {
  .fc-center {
    color: $font-clr !important;
  }

  .fc-time {
    display: none;
  }

  .fc-title {
    color: white;
    font-weight: bold;
  }

  .fc-today {
    background: lightgray !important;
  }

  .fc-popover {
    width: auto !important;
    font-size: 16px;

    .fc-event {
      background: white !important;
      border: none;
    }

    .fc-title {
      color: $font-clr;
    }
  }

  .fc-header {
    background: #0073b7 !important;

    .fc-title {
      color: white !important;
    }
  }

  .fc-close.fc-icon {
    margin-top: 6px !important;
    color: white !important;
  }

  .fc-event {
    margin-bottom: 3px;
    padding: 1px 3px;
  }

  .fc-more {
    font-weight: bold;
    font-size: 13px;
  }
}

.add-on .input-group-btn > .btn {
  border-left-width: 0;
  left: -2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

/* stop the glowing blue shadow */
.add-on .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #cccccc;
}

.input-group-btn {
  margin-left: -36px;
}

.input-group-btn button {
  background-color: white;
  box-shadow: none !important;
}

.duration {
  color: $secondary !important;
  font-weight: bold;
  margin-left: 10px;
}

.clear-date {
  font-size: 18px;
  margin: auto;
  margin-left: 10px;
}

.package-services .ck-editor__editable {
  height: 100px !important;
}

pagination .page-link {
  margin-right: 5px;
  border-radius: 5px;
  font-size: 13px;
  padding: 0.4rem 0.75rem;
}

.nav-tabs {
  .nav-item {
    margin: 0;
  }

  .nav-link {
    background: white;
    margin: 0 5px;
    border: 1px solid #dee2e6;
    border-bottom: none;
    padding: 8px 30px;
    border-radius: 5px 5px 0px 0px;
  }

  .nav-link:hover {
    color: inherit !important;
  }

  .nav-link.active {
    background: $primary;
    color: white !important;
  }
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
    color: #0073b7 !important;
  }
}

.tab-content {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

// *****    Timeline Css    *****
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  div {
    width: 100%;
  }

  ::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: gray;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: -2px;
  }

  .container {
    padding: 10px 0px 10px 30px;
    position: relative;
    background-color: inherit;
  }

  .container::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    left: -2px;
    top: 50%;
    background-color: gray;
    border-radius: 50%;
    z-index: 1;
  }
}

// *****    End of Timeline Css     *****

/******Switch Button**********/
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -3px;
    background-color: rgb(122, 123, 124);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider:before {
  background-color: #2196f3;
}

input:checked + .slider {
  background-color: rgba(33, 149, 243, 0.425);
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/******Switch Button END**********/

.action {
  padding: 5px;

  &.card-action .elipsis-div {
    padding: 5px;
    border-radius: 50%;
    position: absolute;
    width: 30px;
    height: 30px;

    &:hover {
      background-color: #707070;

      i {
        color: #fff;
      }
    }

    i {
      color: #fff;
    }
  }

  &.card-action .kebab-menu {
    background-color: #000;
    opacity: 0.3;
  }

  .kebab-menu {
    border-radius: 50%;
    background-color: transparent;
    width: 30px;
    height: 30px;

    &:hover {
      background-color: #707070;

      i {
        color: #fff;
      }
    }

    i {
      color: blue;
    }
  }
}

.kebab-menu {
  border-radius: 50%;
  background-color: #707070;
  width: 30px;
  height: 30px;

  &:hover {
    background-color: #707070;
    color: #fff;
  }

  i {
    color: #fff;
  }
}

.ui-calendar.ui-calendar-w-btn .ui-inputtext {
  width: calc(100% - 2.357em);
}

.ui-calendar {
  width: 100%;
}

// Layout fix start
.multi-dropdown-list .dropdown-list ul {
  position: relative;
  max-height: 197px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fs-11 {
  font-size: 11px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.text-gray {
  color: #848484;
}

// layout fix end

.full-width {
  width: 100%;
}

.btn-rounded {
  border-radius: 20px;
}

.btn-light {
  color: #212529;
  background-color: #eaeaea;
  border-color: #dee2e6;
}

.close-btn {
  padding: 5px 8px;
  width: 30px;
  height: 30px;
  margin-top: -5px;
  margin-right: -4px;
  background: #fff;
  border-radius: 50%;
  border: transparent;
}

.btn-create-width {
  min-width: 140px;
}

.image-section {
  min-width: auto;

  a.download {
    color: #deeaf3;

    &:hover {
      color: $primary;
    }
  }
}

body .fc .fc-toolbar {
  .fc-state-default {
    background-image: none !important;
  }

  .fc-button {
    border: 1px solid #005b9f !important;
    background-color: $primary;

    &:hover {
      background-color: $primary;
    }
  }
}

.percentage-field .mat-form-field-suffix {
  align-self: center !important;
}

.form-field-zero-padding .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.sticky-header thead {
  border: none;

  tr th {
    position: sticky;
  }
}

.sticky-header-panel {
  position: sticky;
  top: 0px;
  z-index: 1;
  background: #fff;
}

.card-header {
  background-color: $header-clr;
  border-color: #6b6b6d;
  color: #fff;
}

.input-addon-icon-button {
  .mat-form-field-suffix button {
    top: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: -2px !important;
  }
}

.input-addon-button,
.input-addon-icon-button {
  .mat-form-field-flex {
    padding-right: 0px !important;

    .mat-icon {
      height: 21px;
      width: 22px;
    }
  }

  .mat-form-field-suffix {
    button {
      height: 35px;
      // top: -4px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.input-addon-button {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: -1px !important;
  }
}

.suffix-align {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0px !important;
  }
}

.datepicker-inline bs-datepicker-inline {
  bs-datepicker-inline-container {
    width: 100%;

    @media only screen and (max-width: 992px) {
      .bs-datepicker {
        width: 100%;
      }
    }

    .bs-datepicker-container {
      width: 100%;

      @media only screen and (max-width: 576px) {
        padding: 0;
      }

      bs-days-calendar-view {
        width: 100%;
      }
    }
  }
}

ul.wizard,
ul.wizard li {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
}

ul.wizard {
  counter-reset: num;
}

ul.wizard li {
  flex-direction: column;
  align-items: center;
  position: relative;
}

ul.wizard li::before {
  counter-increment: num;
  content: counter(num);
  width: 2.5em;
  height: 2.5em;
  text-align: center;
  line-height: 2.5em;
  border-radius: 50%;
  background: #212529;
  color: #fff;
  z-index: 1;
}

ul.wizard li ~ li::after {
  content: "";
  position: absolute;
  width: 100%;
  right: 50%;
  height: 2px;
  background-color: #212529;
  top: 1.25em;
  z-index: 0;
}

ul.wizard li.active {
  span {
    color: #00bf00;
  }

  &::before {
    background: #00bf00;
    color: #fff;
  }
}

ul.wizard .active ~ li::before,
ul.wizard .active ~ li::after {
  background: #707070;
}

ul.wizard span {
  margin-top: 5px;
  color: #000;
  font-size: 12px;
  font-family: Arial;
}

/*  updated sample  */

ul.wizard li.completed::before {
  /*  number and circle  */
  background: red;
  color: white;
}

ul.wizard li.completed span {
  /*  text  */
  color: red;
}

ul.wizard li.completed + li::after {
  /*  line after circle  */
  background: red;
}

ul.wizard li.completed::after {
  /*  line before circle  */
  background: red;
}

.button-side-icons {
  width: 24px;
  height: 20px;
  mask-position: center;
  background-color: #000;
  mask-repeat: no-repeat;
  mask-size: 16px;

  &.active {
    background-color: #fff;
  }
}

.checkconfirm-icon {
  mask-image: url(/assets/images/svg/check_Confirmed.svg);
}

.check-coloured-icon {
  background: url(/assets/images/svg/checkmark-coloured.svg) no-repeat;
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
  float: left;
  background-position: center;
}

.preparing-icon {
  mask-image: url(/assets/images/svg/Preparing.svg);
}

.pending-icon {
  mask-image: url(/assets/images/svg/Pending.svg);
}

.delivery-icon {
  mask-image: url(/assets/images/svg/Delivery.svg);
  mask-size: 22px;
}

.delivered-icon {
  mask-image: url(/assets/images/svg/delivered.svg);
  mask-size: 22px;
}

.printer-icon {
  mask-image: url(/assets/images/svg/printer.svg);
  background-color: $primary;
}

.printer-button {
  position: relative;

  i.printer-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  &:hover {
    i.printer-icon {
      background-color: #fff;
    }
  }
}

.qr-code-icon {
  width: 24px;
  height: 20px;
  mask-position: center;
  background-color: $dark;
  mask-repeat: no-repeat;
  mask-size: 16px;
  mask-image: url(/assets/images/svg/qr.svg);
}

.text-danger {
  .qr-code-icon {
    background-color: $danger;
  }
}

.text-success {
  .qr-code-icon {
    background-color: $success;
  }
}

.text-primary {
  .qr-code-icon {
    background-color: $primary;
  }
}

button:focus {
  outline: none !important;
}

// *************CkEditor Data for Table in conversation *******//
figure.image {
  position: relative;
}

figure.image-style-side {
  float: right;
}

figure.image > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}

figure.table {
  table,
  th,
  td {
    border: 1px solid black;
  }

  table {
    border-collapse: collapse;
  }
}

.icon-circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50%;
}

.modal-dialog {
  // max-width: 500px;
  margin: 4.75rem auto !important;

  @media only screen and (max-width: 576px) {
    max-width: 430px;
  }

  @media only screen and (max-width: 360px) {
    max-width: 330px;
  }
}

.back-button {
  min-width: 100px !important;
}

.next-button {
  min-width: 100px !important;
}

.submit-button {
  min-width: 100px;
}

.modal-xl {
  max-width: 95%;
}

.selectedAction,
.selectedSubMenu {
  .mat-expansion-indicator {
    &::after {
      color: #fff;
    }
  }
}

#basic-link-dropdown.dropdown-menu-autoset {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  transform: translate3d(-40px, -50px, 0px) !important;
  width: fit-content;
}

.table-responsive {
  &.pb-tbl {
    padding-bottom: 5rem;
  }
}

.meeting-room-tbl {
  #basic-link-dropdown {
    position: absolute;
    top: 0 !important;
    right: 0 !important;
    transform: translate3d(-40px, -20px, 0px) !important;
    width: fit-content;
  }
}

.form-field-middle .mat-form-field-wrapper .mat-form-field-flex {
  vertical-align: middle !important;
}

.selected-tenant-list .mat-chip-list-wrapper .mat-standard-chip {
  padding: 7px 7px;
  font-size: 12px;
  max-width: 200px;
  background-color: #d5e4ff;
  color: #0a23f6;
  .mat-chip-remove {
    color: #0a23f6;
    opacity: 1;
  }
}

.calendar-height .fc-daygrid-body,
.calendar-height .fc-daygrid-body .fc-scrollgrid-sync-table {
  height: 100% !important;
}

ui-checklist-picker-new {
  pagination {
    .pagination {
      margin-bottom: 0;
      justify-content: flex-end;

      @media only screen and (max-width: 768px) {
        justify-content: start;
      }
    }
  }
}

.calendar-height {
  full-calendar {
    .fc-day-sat {
      padding-right: 0.7rem;
    }
  }
}

.number-circle {
  .mat-badge-content {
    color: #000;
    background: #fff;
    border: 1px solid #fe8819;
    width: 24px;
    height: 24px;
  }
}

.form-group {
  label {
    &.mat-form-field-label,
    &.mat-radio-label {
      font-weight: normal;
    }
  }
}

.btn-group-dropdown {
  margin-right: -1px;
  width: 465px;

  .mat-form-field-wrapper .mat-form-field-outline-end {
    border-radius: 0% !important;
  }

  .mat-form-field-wrapper {
    margin: 0 !important;
  }
}

.mat-my-0 {
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
}

.mat-my-0 .mat-form-field-wrapper {
  margin: 0 !important;
}

button[mat-dialog-close] {
  color: $primary-color-text !important;
}

.tenant-multi-checkbox {
  .mat-checkbox {
    width: 100%;
    .mat-checkbox-layout {
      width: 100%;
      .mat-checkbox-inner-container {
        margin-left: 0;
      }
    }
  }
}

.desc-class p {
  word-break: break-all !important;
}

.max-w-60 {
  max-width: 60px;
}

.max-w-70 {
  max-width: 70px;
}

.max-w-100 {
  max-width: 100px;
}
.min-w-170 {
  min-width: 170px;
}
.w-150 {
  width: 150px;
}
.min-w-250 {
  min-width: 250px;
}

.pac-container {
  z-index: 99999 !important;
}

.select-all {
  margin: 5px 17px;
}

.select-all-autocomplete {
  padding: 5px 17px;
  background-color: $secondary-bg;
}

.reminder-popover.mat-menu-panel {
  width: 250px;
  padding: 10px 20px 0;
  border-radius: 5px;
}
.border-left {
  border-left: 1px solid $secondary-text-color;
}

.delete-dialog-title.mat-dialog-title {
  background-color: #d81300;
}

.chip-list.mat-form-field-appearance-fill .mat-form-field-flex {
  height: 280px;
  overflow: auto;
}

.mat-stroked-button.icon-button {
  width: 36px;
  min-width: auto;
  padding: 0px;
}

.ticket-reminder {
  margin-inline-end: 5px;
  border-radius: 16px;
  span {
    display: flex;
    align-items: center;
    img {
      height: 14px;
      margin-inline-end: 0.5rem;
    }
  }
}
.reminder-notification {
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 3px;
  margin-left: 5px;
  background-color: $warning;
  img {
    width: 12px;
    height: 12px;
  }
}

.reminder-notification.medium {
  border-radius: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 3px;
  margin-left: 5px;
  background-color: $warning;
  img {
    width: 16px;
    height: 16px;
  }
}

//chartjs tooltip styles
.tooltip {
  background-color: #3c4575;
  table.tooltipcontent {
    margin-top: 4px;
    margin-bottom: 4px;
    th {
      padding: 0px 10px;
      color: white;
    }
    td {
      text-align: center;
      padding: 0px 10px;
      color: white;
    }
    td.tooltipText {
      font-size: 12px;
    }
    td.tooltipBottomText {
      font-size: 10px;
    }
  }
}

.tooltip-title {
  border-radius: 5px;
  background-color: #48528b;
  text-transform: uppercase;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
}

.tooltip-with-header {
  table.tooltipcontent {
    td {
      text-align: left;
      align-items: center;
      align-content: center;
      padding: 3px 10px;
      span {
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        margin-right: 10px;
        width: 10px;
        vertical-align: middle;
      }
    }
  }
}

.tooltip table.tooltipcontent::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #3c4575 transparent transparent transparent;
}

// barchart legends styles
.pie-chart-legend {
  list-style: none;
  cursor: pointer;
  padding: 20px 20px 0px;
  font-size: 11px;
  color: #898989;
  font-weight: 500;
  margin-bottom: 0;
  white-space: nowrap;
}

.pie-chart-legend li {
  display: block;
  padding: 0 5px;
  margin-bottom: 20px;
}

.pie-chart-legend li.hidden {
  text-decoration: line-through;
}

.pie-chart-legend li span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 10px;
  width: 10px;
  vertical-align: middle;
}

.pie-chart-legend li p {
  padding: 0px 10px 0px 20px;
  color: #3c4575 !important;
  margin-bottom: 0;
}

.barchartLegendBox {
  display: flex;
  justify-content: end;
  margin: 18px 0 20px;
  .barchartLegend {
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #898989;
    font-weight: 500;
    span {
      display: inline-block;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .modal-custom {
    max-width: 500px;
  }
}

@media only screen and (max-width: 360px) {
  .modal-custom {
    max-width: 320px;
  }
}

@media only screen and (min-width: 768px) {
  .modal-custom {
    max-width: 680px;
  }
}

@media only screen and (min-width: 992px) {
  .modal-custom {
    max-width: 750px;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 9999;
}

.toast-container {
  z-index: 9999;
  // .toast-info {
  //   background-color: #2f96b4;
  //   color: $dark;
  // }
  .ngx-toastr {
    width: 480px;
    margin: 10px 0;
    border-radius: 10px;
  }
}
.toast-top-right {
  top: 70px;
}
.toast-close-button {
  right: -0.2em;
  font-size: 27px;
  font-weight: lighter;
}

.no-order-found-text {
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(95, 115, 248);
  margin-bottom: 0;
}

///icon for Meeting room facility
.airport-icon {
  mask-image: url(/assets/images/svg/airport.svg);
}

.ac-icon {
  mask-image: url(/assets/images/svg/facilities/ac.svg);
}

.artificial-sweetner-icon {
  mask-image: url(/assets/images/svg/facilities/artificial-sweetner.svg);
}

.big-markers-icon {
  mask-size: 9px !important;
  mask-image: url(/assets/images/svg/facilities/big-markers.svg);
}

.camcorder-icon {
  mask-image: url(/assets/images/svg/facilities/camcorder.svg);
}

.candy-icon {
  mask-image: url(/assets/images/svg/facilities/candy.svg);
}

.coffee-icon {
  mask-image: url(/assets/images/svg/facilities/coffee.svg);
}

.computer-projector-icon {
  mask-image: url(/assets/images/svg/facilities/computer-projector.svg);
}

.computer-icon {
  mask-image: url(/assets/images/svg/facilities/computer.svg);
}

.conference-phone-icon {
  mask-image: url(/assets/images/svg/facilities/conference-phone.svg);
}

.conference-table-icon {
  mask-image: url(/assets/images/svg/facilities/conference-table.svg);
}

.cookies-icon {
  mask-image: url(/assets/images/svg/facilities/cookies.svg);
}

.cups-icon {
  mask-image: url(/assets/images/svg/facilities/cups.svg);
}

.decaf-coffee-icon {
  mask-image: url(/assets/images/svg/facilities/decaf-coffee.svg);
}

.drinking-water-icon {
  mask-size: 12px !important;
  mask-image: url(/assets/images/svg/facilities/drinking-water.svg);
}

.eraser-icon {
  mask-image: url(/assets/images/svg/facilities/eraser.svg);
}

.extension-cords-icon {
  mask-image: url(/assets/images/svg/facilities/extension-cords.svg);
}

.flip-chart-icon {
  mask-image: url(/assets/images/svg/facilities/flip-chart.svg);
}

.glassboard-with-duster-marker-icon {
  mask-image: url(/assets/images/svg/facilities/glassboard-with-duster-marker.svg);
}

.glasses-icon {
  mask-image: url(/assets/images/svg/facilities/glasses.svg);
}

.hot-water-icon {
  mask-image: url(/assets/images/svg/facilities/hot-water.svg);
}

.ice-cream-icon {
  mask-image: url(/assets/images/svg/facilities/ice-cream.svg);
}

.laser-pointer-icon {
  mask-image: url(/assets/images/svg/facilities/laser-pointer.svg);
}

.napkins-icon {
  mask-image: url(/assets/images/svg/facilities/napkins.svg);
}

.notepad-pen-icon {
  mask-image: url(/assets/images/svg/facilities/notepad-pen.svg);
}

.notepad-icon {
  mask-image: url(/assets/images/svg/facilities/notepad.svg);
}

.overhead-projector-icon {
  mask-image: url(/assets/images/svg/facilities/overhead-projector.svg);
}

.packaged-water-icon {
  mask-image: url(/assets/images/svg/facilities/packaged-water.svg);
}

.paper-plates-icon {
  mask-image: url(/assets/images/svg/facilities/paper-plates.svg);
}

.paper-towels-icon {
  mask-image: url(/assets/images/svg/facilities/paper-towels.svg);
}

.pens-icon {
  mask-image: url(/assets/images/svg/facilities/pens.svg);
}

.podium-icon {
  mask-image: url(/assets/images/svg/facilities/podium.svg);
}

.popcorn-icon {
  mask-image: url(/assets/images/svg/facilities/popcorn.svg);
}

.projector-bulbs-icon {
  mask-image: url(/assets/images/svg/facilities/projector-bulbs.svg);
}

.projector-screen-icon {
  mask-image: url(/assets/images/svg/facilities/projector-screen.svg);
}

.projector-with-screen-icon {
  mask-image: url(/assets/images/svg/facilities/projector-with-screen.svg);
}

.projector-icon {
  mask-image: url(/assets/images/svg/facilities/projector.svg);
}

.room-shades-icon {
  mask-image: url(/assets/images/svg/facilities/room-shades.svg);
}

.slide-projector-icon {
  mask-image: url(/assets/images/svg/facilities/slide-projector.svg);
}

.soda-icon {
  mask-image: url(/assets/images/svg/facilities/soda.svg);
}

.spare-chairs-icon {
  mask-image: url(/assets/images/svg/facilities/spare-chairs.svg);
}

.sticky-notes-icon {
  mask-image: url(/assets/images/svg/facilities/sticky-notes.svg);
}

.stirs-spoons-icon {
  mask-image: url(/assets/images/svg/facilities/stirs-spoons.svg);
}

.sugar-icon {
  mask-image: url(/assets/images/svg/facilities/sugar.svg);
}

.sweet-rolls-icon {
  mask-image: url(/assets/images/svg/facilities/sweet-rolls.svg);
}

.table-chairs-icon {
  mask-image: url(/assets/images/svg/facilities/table-chairs.svg);
}

.tea-coffee-icon {
  mask-image: url(/assets/images/svg/facilities/tea-coffee.svg);
}

.tv-vcr-icon {
  mask-image: url(/assets/images/svg/facilities/tv-vcr.svg);
}

.white-board-marker-icon {
  mask-image: url(/assets/images/svg/facilities/white-board-marker.svg);
}

.white-board-sheets-icon {
  mask-image: url(/assets/images/svg/facilities/white-board-sheets.svg);
}

.white-board-with-duster-marker-icon {
  mask-image: url(/assets/images/svg/facilities/white-board-with-duster-marker.svg);
}

.white-board-icon {
  mask-image: url(/assets/images/svg/facilities/white-board.svg);
}

.wifi-icon {
  mask-image: url(/assets/images/svg/facilities/wifi.svg);
}

//// Icon for meetingroom facilities END

.search-form {
  .mat-form-field,
  ui-multi-select,
  ui-select,
  ui-auto-complete {
    margin-right: 0.5rem;
    @media only screen and (max-width: 1366px) {
      margin-right: 0.5rem;
      width: 17%;
    }
  }
}
.min-w-30 {
  min-width: 30px;
}
.min-w-40 {
  min-width: 40px;
}

.min-w-50 {
  min-width: 50px;
}
.min-w-60 {
  min-width: 60px;
}
.min-w-70 {
  min-width: 70px;
}
.min-w-80 {
  min-width: 80px;
}
.min-w-90 {
  min-width: 90px;
}
.min-w-100 {
  min-width: 100px;
}
.min-w-110 {
  min-width: 110px;
}
.min-w-120 {
  min-width: 120px;
}

.min-w-130 {
  min-width: 130px;
}
.min-w-140 {
  min-width: 140px;
}
.min-w-145 {
  min-width: 145px;
}
.min-w-150 {
  min-width: 150px;
}
.min-w-160 {
  min-width: 160px;
}
.min-w-170 {
  min-width: 170px;
}
.width-30 {
  width: 30px;
}
.width-40 {
  width: 40px;
}

.width-50 {
  width: 50px;
}
.width-60 {
  width: 60px;
}
.width-70 {
  width: 70px;
}
.width-80 {
  width: 80px;
}
.width-90 {
  width: 90px;
}
.width-100 {
  width: 100px;
}
.width-110 {
  width: 110px;
}
.width-120 {
  width: 120px;
}

.width-130 {
  width: 130px;
}
.width-140 {
  width: 140px;
}
.width-150 {
  width: 150px;
}
.width-160 {
  width: 160px;
}
.width-170 {
  width: 170px;
}

fieldset {
  border: 1px solid #d2d4db;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
@import "transport-ui.scss";
@import "space-ui.scss";
@import "roster-ui.scss";
